<template>
  <v-container fluid>
    <v-card class="mt-2">
      <v-container fluid>
        <v-card-title> Organização </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm" @submit.prevent>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      v-model="organizacao.nome"
                      label="Nome"
                      required
                      :rules="[obrigatorio]"
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <CampoDeCnpj v-model="organizacao.documento" label="CNPJ" disabled />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      v-model="organizacao.email"
                      label="E-mail"
                      required
                      :rules="[obrigatorio, emailInvalido]"
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <v-select
                      v-model="organizacao.adquirentePadrao"
                      :items="adquirentesDisponiveis"
                      item-text="adquirente"
                      item-value="adquirente"
                      label="Adquirente Padrão"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="showMessageError">
                  <v-col cols="12" sm="12" md="12">
                    <v-alert dense outlined type="error">
                      {{ messageError }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card max-width="344">
          <v-card-title>Integrações do Sistema</v-card-title>
          <v-row>
            <v-col>
              <v-card-text>
                <h4>Adquirente</h4>
                <v-checkbox
                  v-model="integracoesDisponiveis"
                  label="PayPal"
                  value="PAYPAL"
                  hide-details
                  readonly
                />

                <v-checkbox
                  v-model="integracoesDisponiveis"
                  label="PagSeguro"
                  value="PAGSEGURO"
                  hide-details
                  readonly
                />

                <v-checkbox
                  v-model="integracoesDisponiveis"
                  label="GetNet"
                  value="GETNET"
                  hide-details
                  readonly
                />

                <v-checkbox
                  v-model="integracoesDisponiveis"
                  label="Rede"
                  value="REDE"
                  hide-details
                  readonly
                />
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-text>
                <h4>ERP</h4>
                <v-checkbox
                  v-model="integracoesDisponiveis"
                  label="Systextil"
                  value="SYSTEXTIL"
                  hide-details
                  readonly
                />
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-card-actions>
          <v-col class="text-right" align-self="center">
            <v-btn
              class="ml-4"
              color="primary"
              outlined
              type="submit"
              :disabled="!validForm"
              :loading="salvando"
              @click="salvarOrganizacao"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-container>
    </v-card>
    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-container>
</template>

<script>
import OrganizacaoService from '@/services/organizacao/organizacao'
import Snackbar from '@/models/ui/snackbar'
import Organizacao from '@/models/organizacao/organizacao'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import { obrigatorio, emailInvalido } from '@/shareds/regrasForm'
import store from '@/store'
import { formataCpfCnpj } from '@/shareds/formatacoes'
import CampoDeCnpj from '@/components/ui/CampoDeCnpj'

export default {
  name: 'Organizacao',

  components: {
    CampoDeCnpj,
    SnackbarMessage
  },

  data: () => ({
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: 'Documento', value: 'documento' },
      { text: 'E-mail', value: 'email' },
      { text: 'Ativa', value: 'ativa' },
      { text: '', value: 'acoes', width: '1%', sortable: false }
    ],
    panel: true,
    buscando: false,
    salvando: false,
    organizacao: new Organizacao(),
    editedIndex: -1,
    validForm: true,
    obrigatorio: obrigatorio,
    emailInvalido: emailInvalido,
    showMessageError: false,
    snackbar: new Snackbar(),
    formataCpfCnpj: formataCpfCnpj,
    integracoes: [],
    integracoesDisponiveis: [],
    adquirentesDisponiveis: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Adicionar organização' : 'Editar organização'
    },
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('Organizacao', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('Organizacao', 'EDITAR')
    }
  },

  created() {
    this.buscarOrganizacao()
  },

  methods: {
    buscarOrganizacao() {
      this.buscando = true

      OrganizacaoService.buscarPeloToken()
        .then((response) => {
          this.organizacao = response
          this.buscarIntegracoes()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar as organizações.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscando = false))
    },

    salvarOrganizacao() {
      this.showMessageError = false
      this.$refs.form.validate()

      if (!this.validForm) return

      this.salvando = true

      this.organizacao.email = this.organizacao.email.toLowerCase()

      OrganizacaoService.atualizar(this.organizacao.id, this.organizacao)
        .then(() => {
          this.snackbar.snackTextParam = 'Organização atualizada com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
        })
        .catch((error) => {
          this.messageError = `${error.response.status} | ${
            error.response.data.message === undefined ? error.message : error.response.data.message
          }`
          this.showMessageError = true
        })
        .finally(() => (this.salvando = false))
    },

    async buscarIntegracoes() {
      try {
        this.integracoes = await OrganizacaoService.informarIntegracao(this.organizacao.id)
        this.integracoesDisponiveis = this.integracoes.map(
          (integracao) => integracao.adquirente || integracao.erp
        )
        this.adquirentesDisponiveis = this.integracoes.filter(
          (integracao) => integracao.adquirente && integracao.ativa
        )
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os adquirentes.'
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      }
    }
  }
}
</script>
